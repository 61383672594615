// src/pages/VideoDownloader.tsx

import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { SnackbarCloseReason } from '@mui/material/Snackbar';

interface VideoFormat {
  format_id: string;
  extension: string;
  resolution?: string;
  fps?: number;
  filesize?: number;
  filesize_approx?: number;
  tbr?: number;
  vcodec?: string;
  acodec?: string;
  ext?: string;
}

interface VideoInfo {
  id: number;
  url: string;
  title: string;
  formats: VideoFormat[];
}

const VideoDownloader: React.FC = () => {
  const [url, setUrl] = useState<string>('');
  const [videoInfo, setVideoInfo] = useState<VideoInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFormat, setSelectedFormat] = useState<string>('');
  const [downloadStatus, setDownloadStatus] = useState<string>('');
  const [taskId, setTaskId] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [downloadLink, setDownloadLink] = useState<string>('');
  
  const [transcription, setTranscription] = useState<string>('');
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);
  
  const [comments, setComments] = useState<string[]>([]);
  const [isGeneratingComments, setIsGeneratingComments] = useState<boolean>(false);
  
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'info' | 'warning'
  >('info');

  const showSnackbar = (
    message: string,
    severity: 'success' | 'error' | 'info' | 'warning' = 'info'
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleAlertClose = (event: React.SyntheticEvent) => {
    setOpenSnackbar(false);
  };

  const handleFetchInfo = async () => {
    if (!url) {
      showSnackbar('Пожалуйста, введите ссылку на видео.', 'warning');
      return;
    }

    setLoading(true);
    setVideoInfo(null);
    setDownloadStatus('');
    setDownloadLink('');
    setSelectedFormat('');
    setProgress(0);
    setTranscription('');
    setComments([]);

    try {
      const response = await axios.post<VideoInfo>(
        `${API_BASE_URL}/video/info`,
        null,
        { params: { url } }
      );
      setVideoInfo(response.data);
      showSnackbar('Информация о видео получена успешно!', 'success');
    } catch (error: any) {
      console.error(error);
      showSnackbar(
        'Не удалось получить информацию о видео. Проверьте ссылку.',
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!selectedFormat || !videoInfo) {
      showSnackbar('Пожалуйста, выберите формат для скачивания.', 'warning');
      return;
    }

    setLoading(true);
    setDownloadStatus('Начало скачивания...');
    setDownloadLink('');
    setProgress(0);

    try {
      const response = await axios.post(`${API_BASE_URL}/video/download`, {
        url: videoInfo.url,
        format_id: selectedFormat,
      });

      if (response.data.status === 'SUCCESS') {
        // Видео уже скачано
        setDownloadStatus('Скачивание завершено!');
        setDownloadLink(`${API_BASE_URL}/${response.data.result}`);
        showSnackbar('Скачивание завершено!', 'success');
      } else if (response.data.task_id) {
        // Отслеживание статуса задачи
        setTaskId(response.data.task_id);
        showSnackbar('Скачивание началось!', 'info');

        const interval = setInterval(async () => {
          try {
            const statusResponse = await axios.get(
              `${API_BASE_URL}/task/status/${response.data.task_id}`
            );
            const status = statusResponse.data.status;

            if (status === 'PENDING') {
              setDownloadStatus('Задача в ожидании...');
            } else if (status === 'STARTED' || status === 'PROGRESS') {
              setDownloadStatus('Скачивание...');
              setProgress(statusResponse.data.progress || 0);
            } else if (status === 'SUCCESS') {
              setDownloadStatus('Скачивание завершено!');
              setDownloadLink(`${API_BASE_URL}/${statusResponse.data.result}`);
              showSnackbar('Скачивание завершено!', 'success');
              clearInterval(interval);
            } else if (status === 'FAILURE') {
              setDownloadStatus('Ошибка при скачивании.');
              showSnackbar('Ошибка при скачивании.', 'error');
              clearInterval(interval);
            }
          } catch (error) {
            console.error(error);
            showSnackbar('Ошибка при проверке статуса задачи.', 'error');
            clearInterval(interval);
          }
        }, 2000);
      } else {
        showSnackbar('Неизвестный ответ от сервера.', 'error');
      }
    } catch (error) {
      console.error(error);
      showSnackbar('Не удалось начать скачивание.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTranscribe = async () => {
    if (!downloadLink) {
      showSnackbar('Сначала скачайте видео.', 'warning');
      return;
    }

    // Извлечём имя файла из ссылки
    const filename = downloadLink.split('/').pop();

    if (!filename) {
      showSnackbar('Не удалось определить имя файла.', 'error');
      return;
    }

    setIsTranscribing(true);
    setTranscription('');

    try {
      const response = await axios.post(`${API_BASE_URL}/video/transcribe`, {
        filename: filename,
      });
      setTranscription(response.data.transcription);
      showSnackbar('Транскрипция получена!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Ошибка при получении транскрипции.', 'error');
    } finally {
      setIsTranscribing(false);
    }
  };

  const handleGenerateComments = async () => {
    if (!downloadLink) {
      showSnackbar('Сначала скачайте видео и получите транскрипцию.', 'warning');
      return;
    }

    // Извлечём имя файла из ссылки
    const filename = downloadLink.split('/').pop();

    if (!filename) {
      showSnackbar('Не удалось определить имя файла.', 'error');
      return;
    }

    setIsGeneratingComments(true);
    setComments([]);

    try {
      const response = await axios.post(`${API_BASE_URL}/video/comments`, {
        filename: filename,
      });
      setComments(response.data.comments);
      showSnackbar('Комментарии сгенерированы!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Ошибка при генерации комментариев.', 'error');
    } finally {
      setIsGeneratingComments(false);
    }
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Скачивание Видео
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Вставьте ссылку на видео"
          variant="outlined"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchInfo}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Проверить'}
        </Button>
      </Box>

      {videoInfo && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">{videoInfo.title}</Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="format-select-label">Выберите формат</InputLabel>
            <Select
              labelId="format-select-label"
              value={selectedFormat}
              label="Выберите формат"
              onChange={(e) => setSelectedFormat(e.target.value)}
            >
              {videoInfo.formats.map((format) => (
                <MenuItem key={format.format_id} value={format.format_id}>
                  {format.extension.toUpperCase()} - {format.resolution || 'N/A'} -{' '}
                  {format.vcodec || 'N/A'} / {format.acodec || 'N/A'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDownload}
              disabled={loading || !selectedFormat}
            >
              {loading ? <CircularProgress size={24} /> : 'Скачать'}
            </Button>
            {/* Кнопка получить транскрипцию появляется, если есть ссылка на скачанный файл */}
            {downloadLink && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleTranscribe}
                disabled={isTranscribing}
              >
                {isTranscribing ? <CircularProgress size={24} /> : 'Получить транскрипцию'}
              </Button>
            )}
          </Box>
        </Box>
      )}

      {downloadStatus && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">{downloadStatus}</Typography>
          {downloadStatus === 'Скачивание...' && (
            <LinearProgress variant="determinate" value={progress} />
          )}
          {downloadLink && (
            <Button
              variant="outlined"
              component="a"
              href={downloadLink}
              download
              sx={{ mt: 1 }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Скачать файл
            </Button>
          )}
        </Box>
      )}

      {/* Отображение транскрипции после её получения */}
      {transcription && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Транскрипция</Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {transcription}
          </Typography>
          {/* Кнопка генерации комментариев */}
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={handleGenerateComments}
            disabled={isGeneratingComments}
          >
            {isGeneratingComments ? <CircularProgress size={24} /> : 'Сгенерировать комментарии'}
          </Button>
        </Box>
      )}

      {/* Отображение комментариев после их генерации */}
      {comments.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Сгенерированные Комментарии</Typography>
          {comments.map((comment, index) => (
            <Typography key={index} variant="body1" sx={{ marginBottom: 4 }}>
              {comment}
            </Typography>
          ))}
        </Box>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={(e, r) => handleSnackbarClose(e, r)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VideoDownloader;
